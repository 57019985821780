import React, {useEffect} from 'react';

function InfiniteScroller(props: {
    isVisible?: boolean,
    fetchMore: () => void,
}) {

    useEffect(() => {
        if (props.isVisible) {
            props.fetchMore();
        }
    },[props.isVisible]);

    return (
        <div></div>
    );
}

export default InfiniteScroller;
