import SortIcon from "./SortIcon";
import classNames from "classnames";
import styles from "./styles/FilesFoldersBlock.module.scss";

const tableCellClassName = classNames({
    [styles.tableCell]: true,
});

export default function FilesListHeader(props) {
    return <div className={tableCellClassName}>
        {props.name}
    </div>
}
