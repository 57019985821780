import styles from "./styles/SaveCancelButtons.module.scss"
import React from "react";
import classNames from "classnames";


const SaveCancelButtons = (props: {
    editMode: boolean,
    setEditing: (value: boolean, cancel: boolean) => void,
    disabled?: boolean,
}) => {

    const saveButtonClassName = classNames({
        [styles.saveButton]: true,
        [styles.disabledButton]: props.disabled,
    });

    return (
        <div className={styles.editButtonContainer}>
            <div className={saveButtonClassName}>
                <button type="button"
                        disabled={props.disabled}
                        className={saveButtonClassName}
                        onClick={() => props.setEditing(!props.editMode, false)}
                >
                    Save Changes
                </button>
            </div>
            <div className={styles.cancelButton}>
                <button type="button"
                        className={`${styles.controlButton} ${styles.closeButton} btn`}
                        onClick={() => props.setEditing(!props.editMode, true)}
                >
                    Cancel
                </button>
            </div>
        </div>
    )
};

export default SaveCancelButtons;
