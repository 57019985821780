import React, {useCallback, useContext, useEffect, useState} from 'react';
import styles from './styles/FilesFoldersBlock.module.scss';
import {observer} from 'mobx-react';
import BlockTitle from "../_BlockTitle";
import FilesList from "./FilesList";
import {getContentItemFileViewerType} from "../../SchoolBlocks/SharedFilesBlock/SharedFilesBlock";
import {FileViewer} from "../../SchoolBlocks/SharedFilesBlock/NormalizedFileBlock";
import {CSSTransition} from "react-transition-group";
import classNames from "classnames";
import {ITerraceProps} from "../Terrace";
import {StoreContext} from "../../../../stores/StoreLoader";
import WatsonApi from "../../../../backends/WatsonApi";
import NotificationManager from "../../../notifications/NotificationManager";
import {runInAction} from "mobx";
import {getIconSet} from "./fileUtilities";
import {EditDropDownItem} from "../EditDropDown";
import FontAwesome from "../../../utilities/FontAwesome";
import SaveCancelButtons from "../SaveCancelButtons";

import {
    IReactiveFileContentItem,
    IReactiveFolderContentItem,
} from "../../../../stores/SchoolFeedStore";

const transitionClassNames = {
    enter: styles.transitionEnter,
    enterActive: styles.transitionEnterActive,
    enterDone: styles.transitionEnterDone,
    exit: styles.transitionExit,
    exitActive: styles.transitionExitActive,
    exitDone: styles.transitionExitDone,
};

type IFilesFoldersListAndPreviewProps = {
    expandedView: boolean,
    filesAndFolders: Array<IReactiveFileContentItem | IReactiveFolderContentItem>,
    aboveTheFold?: boolean,
    handleFetchMore?: () => void,
    shouldFetchMore?: boolean,
    useInfiniteScroll?: boolean,
    handleDelete: (contentItem: ContentItem) => void,
    blockObj: IFilesTerraceBlock,
    sortBy?: string,
    setEditing?: (value: boolean, cancel: boolean) => void,
} & ({
    editing: true,
    setSortBy: (value: string) => void,
} | ({
    editing: false,
    setSortBy?: (value: string) => void,
}))

export function canPreviewContentItem(item, titleOrg) {
    if (!item) return false;
    const fileOrigin = getIconSet(item.type);
    const provider = titleOrg.json_data.settings?.identity?.provider;
    return item.is_file && (fileOrigin === "google" || fileOrigin === provider) && (item.json_data.file.embedded_view_link || !item.type.includes("o365"));
}

export const FilesFoldersListAndPreview = observer((props: IFilesFoldersListAndPreviewProps) => {
    const {interfaceStore, organizationStore} = useContext(StoreContext);
    const {aboveTheFold = true} = props;
    const [selectedContentItem, setSelectedContentItem] = useState<FileContentItem | undefined>(props.filesAndFolders[0]?.is_file ? props.filesAndFolders[0] as FileContentItem : undefined);
    const [previewIsExpanded, setPreviewIsExpanded] = useState(false);
    const hasValidViewLink = canPreviewContentItem(selectedContentItem, organizationStore.organization);
    const filesFoldersContainerClassName = classNames({
        [styles.filesFoldersContainer]: true,
        [styles.filesFoldersContainerTerrace]: !props.expandedView && !interfaceStore.currentFullUrl.includes('schoolfeed'),
        [styles.filesFoldersContainerSelected]: selectedContentItem && props.aboveTheFold,
    })

    const selectedContainerClassName = classNames({
        [styles.contentContainer]: true,
        [styles.contentContainerSelected]: hasValidViewLink,
    })

    const filePreviewClassName = classNames({
        [styles.filePreview]: true,
        [styles.filePreviewSelected]: hasValidViewLink,
        [styles.filePreviewExpanded]: previewIsExpanded,
    })


    const isMobile= interfaceStore.breakpoint !== "break-point-lg"

    return (
        <div className={filesFoldersContainerClassName}>
            <div className={selectedContainerClassName}>
                <FilesList
                    files_folders={props.filesAndFolders}
                    setSelectedContentItem={setSelectedContentItem}
                    selectedItem={selectedContentItem}
                    setPreviewIsExpanded={setPreviewIsExpanded}
                    previewIsExpanded={previewIsExpanded}
                    aboveTheFold={aboveTheFold}
                    handleFetchMore={props.handleFetchMore}
                    shouldFetchMore={props.shouldFetchMore}
                    maxHeight={props.expandedView ? "initial" : undefined}
                    useInfiniteScroll={props.useInfiniteScroll}
                    handleDelete={props.handleDelete}
                />
                {props.editing && props.blockObj &&
                    <>
                        <div className={styles.sortByContainer}>
                            <div className={styles.inputGroup}>
                                <label htmlFor="settings_sortBy">Sort by:</label>
                                <div>
                                    <select className={"form-control"} value={props.sortBy}
                                            onChange={e => props.setSortBy(e.target.value)}>
                                        <option value={"title"}>Filename (A-Z)</option>
                                        <option value={"-title"}>Filename (Z-A)</option>
                                        <option value={"-updated_at"}>Last Modified Date (Newest-Oldest)</option>
                                        <option value={"updated_at"}>Last Modified Date (Oldest-Newest)</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        {props.setEditing && <SaveCancelButtons editMode={props.editing} setEditing={props.setEditing}/>}
                    </>
                }
            </div>
            {!isMobile &&
                <div className={filePreviewClassName}>
                    {hasValidViewLink && selectedContentItem && <FileViewer
                        viewerType={getContentItemFileViewerType(selectedContentItem)}
                        viewLink={selectedContentItem.json_data.file.view_link}
                        embeddedViewLink={selectedContentItem?.json_data.file.embedded_view_link}
                        thirdPartyId={selectedContentItem.third_party_id}
                        uniqueId={selectedContentItem.id}
                    />}
                </div>
            }
        </div>)
})

interface FilesFoldersBlockProps extends ITerraceProps {
    blockObj: Omit<IFilesTerraceBlock, "content_items"> & {content_items: Array<IReactiveFileContentItem | IReactiveFolderContentItem>},
}

const FilesFoldersBlock = observer((props: FilesFoldersBlockProps) => {
    const {gridStore, sidebarStore} = useContext(StoreContext);

    const [editing, setEditing] = useState(false);
    const [sortBy, setSortBy] = useState(props.blockObj.json_data?.files?.sort_by ? props.blockObj.json_data?.files.sort_by : "-updated_at");
    const [contentItems, setContentItems] = useState(props.blockObj.content_items);
    const [page, setPage] = useState(2)
    const [shouldShowMoreButton, setShouldShowMoreButton] = useState(props.blockObj.is_more)
    // This should be taken out after the backend is updated to handle the new sorting values
    const [sortingValue, setSortingValue] = useState(sortBy);
    const saveSortBy = useCallback(async () => {
        try {
            const client = await WatsonApi();
            const response = await client.apis.organizations.organizations_terraces_partial_update({
                organization_pk: props.blockObj.organization_id,
                id: props.blockObj.id,
                data: {
                    json_data: {
                        files: {
                            sort_by: sortBy,
                        },
                    },
                },
            });
            NotificationManager.success("Sort By Updated!");
            runInAction(() => {
                gridStore.blocks.set(props.blockObj.id, JSON.parse(response.data));
            });
            setContentItems(JSON.parse(response.data).content_items);
            setEditing(!editing);
            setSortingValue(sortBy)
        } catch (e) {
            setSortBy(props.blockObj.json_data.files.sort_by);
            NotificationManager.error("Something went wrong! Please try again.");
        }
    }, [sortBy]);
    async function _setEditing(value, cancel) {
        if (value){
            setEditing(value);
        }
        else if (!cancel) {
            await saveSortBy();
        }
        else if (cancel){
            setSortBy(props.blockObj.json_data.files.sort_by);
            setEditing(false);
        }
    }

    const handleFetchMore = async () => {
        const client = await WatsonApi();
        const obj = await client.apis.organizations.organizations_content_items_list({
            'organization_pk': props.blockObj.organization_id,
            'is_folder': true,
            'is_file': true,
            'page_size': 12,
            'page': page,
            'ordering': sortingValue,
            'expand': ['organization'],
        })
        const json_result = JSON.parse(obj.data);
        const newData = json_result.data;
        const next_link = json_result.links.next;
        if (next_link === null) {
            setShouldShowMoreButton(false);
        }
        setContentItems(prevContentItems => [...prevContentItems, ...newData]);
        setPage(page + 1);
        return newData;
    }

    const onClick = () => {
        sidebarStore.setSidebar(
            {
                view: "AddSocial",
                sbtype: 'GOOGLE_DRIVE',
            }
        )
    }

    useEffect(() => {
        // Need to run a useEffect to update file published status / styling in Terrace view
        setContentItems(props.blockObj.content_items)
    },[props.blockObj.content_items])


    const filesFoldersBlockDropDownItems: EditDropDownItem[] = [
        {
            title: 'Add File',
            icon: <FontAwesome prefix={'fas'} fixedWidth className="fa-plus"/>,
            onClick: onClick,
        },
        {
            title: 'Sort By',
            icon: <FontAwesome prefix={'fas'} fixedWidth className="fa-pencil-alt"/>,
            onClick: () => _setEditing(!editing, true),
        },
        {
            title: 'Manage Files/Folders',
            icon: <FontAwesome prefix={'fas'} fixedWidth className="fa-pencil-alt"/>,
            onClick: () => sidebarStore.setSidebar({view: "Social"}),
        },
    ]

    return (
        <section>
            <BlockTitle blockObj={props.blockObj}
                        aboveTheFold={props.aboveTheFold}
                        setEditing={_setEditing}
                        editing={editing}
                        dropDownItems={filesFoldersBlockDropDownItems}
                        allowSave={true}
                        showViewMore={false}
            />
            <div className={styles.transitionWrapper}>
                <CSSTransition appear classNames={transitionClassNames} in={props.aboveTheFold} timeout={500}>
                    <FilesFoldersListAndPreview
                        expandedView={false}
                        handleFetchMore={handleFetchMore}
                        shouldFetchMore={shouldShowMoreButton}
                        filesAndFolders={contentItems}
                        aboveTheFold={props.aboveTheFold}
                        handleDelete={props.handleDeleteContentItem}
                        editing={editing}
                        setEditing={_setEditing}
                        blockObj={props.blockObj}
                        sortBy={sortBy}
                        setSortBy={setSortBy}
                    />
                </CSSTransition>
            </div>

        </section>
    );
});

export default FilesFoldersBlock;
